import { FunctionComponent, useEffect, useState, useMemo } from "react";
import {
  DataTable,
  IDataTableParams,
  DefaultDataTableParams,
  TableSelection,
  IBulkActionOptions,
} from "../../dataTable/DataTable";
import { NotificationHelper } from "../../common/helpers/NotificationHelper";
import { TableHelper } from "../../common/helpers/TableHelper";
import { EndpointDetails, EndpointRequestType, EndpointType, UamService } from '../../common/services/UamService';
import { DropdownItem, Notifications } from "@integrate/hedgehogger";

interface EndpointsTableProps {
	bulkActionOptions?: IBulkActionOptions;
	selectedEndpoints: EndpointDetails[];
	handleSelectedEndpoints: (selectedEndpoints: EndpointDetails[]) => void;
	refresh: boolean;
}

export const EndpointsTable: FunctionComponent<EndpointsTableProps> = ({
	bulkActionOptions,
	handleSelectedEndpoints,
	selectedEndpoints,
	refresh
}) => {
	const [notifications, setNotifications]: any[] = useState([]);
	const [params, setParams] = useState<IDataTableParams>(DefaultDataTableParams);
	const [totalEndpoints, setTotalEndpoints] = useState(0);
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState<EndpointType>();
	const [filterOptions, setFilterOptions] = useState<string[]>([]);
	const [searchBy, setSearchBy] = useState<DropdownItem>();

	const getEndpoints = async (query: EndpointRequestType) => {
		try {
			setLoading(true);
			const results = await UamService.getEndpoints(query);

			if (!results) {
				throw new Error('error getting endpoints list');
			}

			setResults(results);
			setTotalEndpoints(results.totalRecords);
		} catch (e) {
			setNotifications([NotificationHelper.error('Unable to get endpoints, please try again.')]);
		}

		setLoading(false);
	};

	useEffect(() => {
		let cloud = '';
		let enabled;
		let authorizationPolicy = '';

		// INFO: Reset selected endpoints when search changes
		handleSelectedEndpoints([]);

		filterOptions.forEach((option) => {
			const [key, value] = option.split(':');
			switch (key) {
				case 'enabled':
					enabled = value.length ? value === 'true' : undefined;
					break;
				case 'cloud':
					cloud = cloud.length ? cloud.concat(',', value) : value;
					break;
				case 'authPolicy':
					authorizationPolicy = authorizationPolicy.length ? authorizationPolicy.concat(',', value) : value;
					break;
			}
		});

		getEndpoints({
			skip: params.skip,
			take: params.take,
			search: params.search,
			searchBy,
			enabled,
			cloud,
			authorizationPolicy
		});
	}, [params.search, params.skip, params.take, filterOptions, refresh]);

	const tableData = useMemo(() =>  UamService.transformEndpointDataIntoRows(results?.records ?? [], selectedEndpoints), [results, selectedEndpoints]);

	const handleChangeParams = (newParams: IDataTableParams) => {
		setParams(newParams);
	};

	const handleSetSelectedItems = (selectedItems: TableSelection[]) => {
		const newSelection = TableHelper.calcSelectedRows(selectedEndpoints, selectedItems, results?.records ?? []);
		handleSelectedEndpoints(newSelection);
	};

	const handleFilters = (selectedOptions: string[]) => {
		setFilterOptions(selectedOptions);
	};

	const handleSearchByOption = (option: DropdownItem) => {
		setSearchBy(option);
	};

	return (
		<>
			<DataTable
				name="Endpoints"
				columns={UamService.tableHeaderColumns}
				data={tableData}
				onChangeParams={handleChangeParams}
				totalAmount={totalEndpoints}
				loading={loading}
				onSelectItems={handleSetSelectedItems}
				bulkActionOptions={bulkActionOptions}
				filterOptions={UamService.filterOptions}
				handleFilter={handleFilters}
				numSelectedItems={selectedEndpoints.length}
				searchByOptions={UamService.searchByOptions}
				handleSearchByOption={handleSearchByOption}
			/>
			<Notifications notifications={notifications} duration={4000} />
		</>
	);
};
