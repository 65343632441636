import { DropdownItem, FilterOptions } from '@integrate/hedgehogger';
import { IDataTableHeader, IDataTableRowAction, IParsedDataTableRow } from '../../dataTable/DataTable';
import { httpRequest, httpRequestWithoutParsing } from './api';
import { DateTime } from 'luxon';
import { TableHelper } from '../helpers/TableHelper';

export type EndpointType = {
	records: EndpointDetails[];
	totalRecords: number;
	currentRecordCount: number;
	skip: number;
	take: number;
};

export type EndpointDetails = {
	id: string;
	httpMethod: string;
	endpoint: string;
	cloud: string;
	authorizationPolicy: string;
	scopePermissionId: string;
	serviceName: string;
	enabled: boolean;
	isDeleted: boolean;
	createdOn: string;
	createBy: string;
	lastModifiedBy?: string;
	lastModifiedOn?: string;
};

export type ToggleEndpointsRequest = {
	ids: string[];
	enabled: boolean;
};

export type EndpointRequestType = {
	skip: number;
	take: number;
	search?: string;
	enabled?: boolean;
	isDeleted?: boolean;
	searchBy?: DropdownItem;
	cloud: string;
	authorizationPolicy: string;
};

export class UamService {
	static readonly BASE_URL = '/api/uam/v1/endpoints';

	static getEndpoints = (query: EndpointRequestType): Promise<EndpointType> => {
		let queryString = '';
		const queryParams = [
			{ key: 'skip', value: query.skip },
			{ key: 'take', value: query.take },
			{ key: 'isDeleted', value: query.isDeleted },
			{ key: 'cloud', value: query.cloud },
			{ key: 'authorizationPolicy', value: query.authorizationPolicy },
			{ key: 'enabled', value: query.enabled }
		];

		switch (query.searchBy?.id) {
			case 'endpoint':
				queryParams.push({ key: 'endpoint', value: query.search });
				break;
			case 'serviceName':
				queryParams.push({ key: 'serviceName', value: query.search });
				break;
		}

		queryString = queryParams
			.filter((param) => param.value !== undefined && param.value !== null)
			.map((param) => `${param.key}=${param.value}`)
			.join('&');

		return httpRequest({
			url: `${UamService.BASE_URL}?${queryString}`,
			method: 'GET',
			params: {}
		});
	};

	static toggleEndpoints = (data: ToggleEndpointsRequest): Promise<string> => {
		return httpRequestWithoutParsing({
			url: `${UamService.BASE_URL}/toggle`,
			method: 'PUT',
			data: data
		});
	};

	static bulkOptions: DropdownItem[] = [
		{
			id: 'enable',
			value: 'Enable'
		},
		{
			id: 'disable',
			value: 'Disable'
		}
	];

	static searchByOptions: DropdownItem[] = [
		{
			id: 'endpoint',
			value: 'Endpoint',
			isSelected: true
		},
		{
			id: 'serviceName',
			value: 'Service Name'
		}
	];

	static filterOptions: FilterOptions = {
		Cloud: {
			'cloud:any': 'Any',
			'cloud:aws': 'Aws',
			'cloud:azure': 'Azure'
		},
		'Authorization Policy': {
			'authPolicy:anonymous': 'Anonymous',
			'authPolicy:authorizationPolicy': 'Authorization Policy',
			'authPolicy:accountManagementAuthorizationPolicy': 'Account Management Authorization Policy'
		},
		Enabled: {
			'enabled:true': 'True',
			'enabled:false': 'False'
		}
	};

	static tableHeaderColumns: IDataTableHeader[] = [
		{
			label: 'Method',
			id: 'httpMethod',
			hideDisabled: true,
			disableSort: true
		},
		{
			label: 'Endpoint',
			id: 'endpoint',
			hideDisabled: true,
			disableSort: true
		},
		{
			label: 'Cloud',
			id: 'cloud'
		},
		{
			label: 'Authorization Policy',
			id: 'authorizationPolicy',
			disableSort: true
		},
		{
			label: 'Service Name',
			id: 'serviceName',
			disableSort: true
		},
		{
			label: 'Active',
			id: 'enabled',
			disableSort: true
		},
		{
			label: 'Created On',
			id: 'createdOn',
			disableSort: true
		},
		{
			label: 'Updated On',
			id: 'lastModifiedOn',
			disableSort: true
		}
	];

	static transformEndpointDataIntoRows = (
		endpoints: EndpointDetails[],
		selectedEndpoints: EndpointDetails[]
	): IParsedDataTableRow[] => {
		const parsedData: IParsedDataTableRow[] = endpoints.map((endpoint: EndpointDetails) => {
			const isChecked = TableHelper.checkIdExistsInArray(endpoint.id, selectedEndpoints);
			return UamService.createDataTableRow(endpoint, [], isChecked);
		});
		return parsedData;
	};

	static createDataTableRow = (
		endpoint: EndpointDetails,
		actionArray: IDataTableRowAction[],
		isChecked: boolean
	): IParsedDataTableRow => {
		return {
			id: endpoint.id,
			actions: actionArray,
			isDefaultChecked: isChecked,
			columns: [
				{
					id: 'httpMethod',
					value: endpoint.httpMethod
				},
				{
					id: 'endpoint',
					value: endpoint.endpoint
				},
				{
					id: 'cloud',
					value: endpoint.cloud
				},
				{
					id: 'authorizationPolicy',
					value: endpoint.authorizationPolicy
				},
				{
					id: 'serviceName',
					value: endpoint.serviceName
				},
				{
					id: 'enabled',
					value: endpoint.enabled ? 'Yes' : 'No'
				},
				{
					id: 'createdOn',
					value: this.formatDate(endpoint.createdOn)
				},
				{
					id: 'lastModifiedOn',
					value: endpoint.lastModifiedOn?.length ? this.formatDate(endpoint.lastModifiedOn) : ''
				}
			]
		};
	};

	static formatDate = (timestamp: string): string =>
		DateTime.fromISO(timestamp, {
			zone: 'utc',
			locale: 'en'
		})
			.setZone('local')
			.toFormat('MMM dd yyyy HH:mm:ss')
			.replace(/\Sep\b/, 'Sept');
}
