import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect  } from "react-router-dom";
import { UsersPage } from "./users/UsersPage";
import { OrgsPage } from "./orgs/OrgsPage";
import { OrgPage } from "./orgs/orgPage/OrgPage";
import { LoginPage } from "./login/LoginPage";
import { NavBar } from "./navbar/NavBar";
import styled from "styled-components";
import { useAuth0, IdToken } from "@auth0/auth0-react";
import { UserPage } from './users/UserPage';
import { ClaimsPage } from './claims/ClaimsPage';
import { AutoLoginPage } from './login/AutoLoginPage';
import UamPage from './uam/UamPage';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  height: 99.99vh;
`;

const Content = styled.div`
  overflow: auto;
`;

function App(): JSX.Element {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [idToken, setIdToken] = useState<IdToken | undefined>();
  const [hasPermissionManagementAccess, setHasPermissionManagementAccess] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      const token: IdToken | undefined = await getIdTokenClaims();
      localStorage.setItem('AUTH_TOKEN', token ? token.__raw : 'default_token');
      
      setIdToken(token);
      setHasPermissionManagementAccess(token?.['https://uam.integrate.com/user_access']?.permissionManagementAccess);
    }

    if (isAuthenticated) {
      getToken();
    } else {
      setIdToken(undefined);
      localStorage.setItem('AUTH_TOKEN', '');
    }
  }, [isAuthenticated])

  const hasToken = isAuthenticated && idToken;

  return (
		<BrowserRouter>
			<Layout>
				<NavBar idToken={idToken} />
				<Content>
					<Switch>
						<Route exact path="/" component={hasToken ? OrgsPage : LoginPage} />
						<Route exact path="/auth0" component={AutoLoginPage} />
						<Route exact path="/orgs" component={hasToken ? OrgsPage : LoginPage} />
						<Route exact path="/orgs/:orgId" component={hasToken ? OrgPage : LoginPage} />
						<Route exact path="/users/:userId" component={hasToken ? UserPage : LoginPage} />
						<Route exact path="/users" component={hasToken ? UsersPage : LoginPage} />
						<Route exact path="/claims" component={hasToken ? ClaimsPage : LoginPage} />
						{hasPermissionManagementAccess && <Route exact path="/uam" component={hasToken ? UamPage : LoginPage} />}
						<Route render={() => <Redirect to={{ pathname: '/' }} />} />
					</Switch>
				</Content>
			</Layout>
		</BrowserRouter>
  );
}

export default App;

