import React, { useEffect, useState } from 'react'
import { EndpointsTable } from './Endpoints/EndpointsTable';
import { Button, DropdownItem, Modal, Notifications } from '@integrate/hedgehogger'
import { PageHeader } from '../pageHeader/PageHeader'
import { Wrapper } from '../shared/StyledComponents'
import { EndpointDetails, UamService } from '../common/services/UamService'
import { INotification, NotificationHelper } from '../common/helpers/NotificationHelper';

function UamPage() : JSX.Element {
    const [show, setShow] = useState(false);
    const [selectedEndpoints, setSelectedEndpoints] = useState<EndpointDetails[]>([]);
    const [editState, setEditState] = useState('');
	const [notifications, setNotifications] = useState<INotification[]>([]);
	const [refresh, setRefresh] = useState(false);
    
	const handleBulkAction = (bulkActionItem: DropdownItem) => {
		switch (bulkActionItem.id) {
			case 'enable':
                setEditState('enable');
                setShow(true);
				break;
			case 'disable':
                setEditState('disable');
                setShow(true);
				break;
			default:
                setEditState('');
				break;
		}
	};

    const handleOnClose = () => {
        setShow(false);
    };

    
    const handleOnConfirm = async () => {
		try {
			const result = await UamService.toggleEndpoints({
				ids: selectedEndpoints.map((val) => val.id),
				enabled: editState == 'enable' ? true : false
			});

			if (!(result && typeof result === 'string' && result === 'success')) {
				throw new Error('error getting endpoints list');
			}

			setSelectedEndpoints([]);
			setRefresh(!refresh);
		} catch (e) {
			setNotifications([NotificationHelper.error('Unable to update the endpoints, please try again.')]);
		}
        
		setShow(false);
    }

	useEffect(() => {
		setSelectedEndpoints([]);
	}, [refresh]);

  return (
		<Wrapper>
			<PageHeader title="Endpoints Management"></PageHeader>
			<EndpointsTable
				bulkActionOptions={{
					onBulkItemClicked: handleBulkAction,
					bulkActionItems: UamService.bulkOptions
				}}
				selectedEndpoints={selectedEndpoints}
				handleSelectedEndpoints={setSelectedEndpoints}
				refresh={refresh}></EndpointsTable>
			<Modal
				title={'Confirmation'}
				active={show}
				closeCallback={handleOnClose}
				buttons={[
					<Button key="cancel" label="Cancel" onClick={handleOnClose}></Button>,
					<Button key="confirm" label="Confirm" isDanger onClick={handleOnConfirm}></Button>
				]}>
				<p>
					Are you sure you want to {editState} {selectedEndpoints.length} selected endpoints? They will be
					marked as {editState == 'enable' ? 'active' : 'inactive'}
				</p>
			</Modal>
			<Notifications notifications={notifications} duration={4000} />
		</Wrapper>
  );
}

export default UamPage;
