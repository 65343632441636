
import styled from "styled-components";
import { Filter, Styles } from "@integrate/hedgehogger";

export const Wrapper = styled.div`
margin: 4px 32px;
`;

interface HeaderWrapperProps {
  hasButton?: boolean,
  withOptional?: boolean,
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
	display: grid;
	grid-template-columns: ${(props) => (props.withOptional ? '20% 20% auto' : '20% auto')};
	grid-gap: 10px;
	align-items: center;
	justify-items: start;
	margin: 16px 0;
	text-align: start;

	${(props) =>
		props.withOptional
			? 'button:not(:first-child) { justify-self: end; margin-top: 6px; }'
			: 'button { justify-self: end; margin-top: 6px; }'}
`;

export const Footer = styled.div`
justify-items: center;
align-items: start;
padding: 4px 0px;
display: grid;
grid-template-columns: 33% 33% 34%;
height: 168px;
span {
  justify-self: start;
}
`;

export const MainBody = styled.div``;

export const H1 = styled.h1`
font-size: 1.7em;
justify-self: start;
margin: 4px 0;
opacity: .97;
`;

export const BulkH1 = styled.h1`
font-weight: normal;
font-size: 1.6em;
margin: 4px 0 8px 0;
`;

export const ButtonWrapper = styled.div`
button {
  margin-top: 8px;
  z-index: 5;
}

div {
  margin-top: 10px;
}
`;

export const DropdownWrapper = styled.div`
display: inline-block;
justify-self: end;
margin-top: 4px;
`;

export const FormLabel = styled.span`
padding-top: 10px;
font-size: 14px;
color: ${Styles.colors.neutral900}${Styles.colors.opacity70};
`;

export const Centered = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center; 
  align-self: center;
`

export const LoadingAnimation = styled.div`
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-name: loading;
  color: ${Styles.colors.blue500};
  font-weight: bold;
  font-size: 1.5em;

  @keyframes loading {
    from {
      font-size: 1.5em;
    }

    to {
      font-size: 2em;
    }
  }
`

export const ErrorMessage = styled.div`
  color: ${Styles.colors.red500};
  font-size: 1.2em;
  font-weight: bold;
`;

export const SaveButtonWrapper = styled.div`
margin: 24px 0;
`;

export const SearchWrapper = styled.div`
  padding-bottom: 6px;
`;

export const ModalGrid = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  justify-items: stretch;
`;

export const SwitchWrapper = styled.label`
  display: inline-block;
  margin-top: 14px;
`;

export const SwitchLabel = styled.label`
  display: inline-block;
  margin-left: 8px;
`;

export const FilterWrapper = styled(Filter)`
  margin-bottom: 16px;
`
